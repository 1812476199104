.swal2-title {
  font-family: "Inter", sans-serif !important;
}

.swal2-progress-steps .swal2-progress-step,
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step,
.swal2-progress-steps .swal2-progress-step-line {
  background: var(--primary) !important;
}

.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step,
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: var(--teal) !important;
  opacity: 0.5 !important;
}

.swal2-radio {
  accent-color: var(--primary) !important;
}

.swal2-footer {
  border-color: var(--gray) !important;
}

.swal2-loader {
  border-color: var(--primary) transparent var(--primary) transparent !important;
}
